import '../Styles/team.css';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import profileicon from '../Assets/profile-icon.png';

const MeetTheTeam = () => {
    return (
      <div className='team-page'>
        <div>
          <div>
            <h1 className='header'>Meet the Team</h1>
          </div>
          <div>
            <h4 className='header'>Our team currently consists of six members, all of which bring different talents to Chef Katers.</h4>
          </div>
        </div>
        
      <div className='team-cards'>  
      <CardGroup>
      <Card text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Faiz Shakil - Chief Executive Officer (CEO)</Card.Header>
              <Card.Body>
                <Card.Text>
                As the CEO, Faiz is responsible for creating strategies, setting goals, collaborating with the team, and leading members into the direction of creating a successful business. By using his previous experiences from school and the workplace, he is able to critically think about all aspects of the business and ensure the correct decisions are made. In his spare time, he enjoys being around the sport of basketball and loves to travel around the world.
                </Card.Text>
              </Card.Body>
          </Card>
          <Card  text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Abdul Moiz - Chief Technology Officer (CTO)</Card.Header>
              <Card.Body>
                <Card.Text>
                Abdul is our Chief Technology Officer and responsible for all technical aspects of the Chef Katers application. From the system design and architecture to the actual implementation of Chef Katers, Abdul is the subject matter expert. He was trusted with selecting the technology stack and ensuring the application follows all best practices in the Software Development Life Cycle. Abdul's goal is to establish Chef Katers as an industry leading technology, that priorities reliability and customer satisfaction. He is an honours graduate in Computer Science who is a self motivated technologist and aspiring industry leader.
                </Card.Text>
              </Card.Body>
          </Card>
          <Card  text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Umer Aqil - Chief Operating Officer (COO)</Card.Header>
              <Card.Body>
                <Card.Text>
                Currently a full-time student, Umer has grown tremendously through his contributions to Chef Katers. He plays a key role in the daily operations of the business related to product development, marketing campaigns, and market research and analysis. He continues to show why he is the head of Public Relations at his school due to his ability to create relationships with our restaurants and customers. 
                </Card.Text>
              </Card.Body>
          </Card>
      </CardGroup>
          
      <CardGroup>
      <Card  text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Rameez Butt - Chief Financial Officer (CFO)</Card.Header>
              <Card.Body>
                <Card.Text>
                Rameez is the CFO for Chef Katers who looks over the financial side of the business. His work relates a lot to analyzing the company’s finances, costs, revenue streams, and profitability. Due to exposure with different industries, he is able to view financial issues through different lenses and choose the best course of action. In his spare time, he enjoys playing sports, watching movies, and travelling.
                </Card.Text>
              </Card.Body>
          </Card>
        <Card  text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Danyal Yousuf - Frontend Developer</Card.Header>
              <Card.Body>
                <Card.Text>
                Desc goes here
                </Card.Text>
              </Card.Body>
          </Card>
        <Card  text="light" className="mb-4 mt-4 custom-card">
            <Card.Img variant="top" src={profileicon} />
              <Card.Header>Shaeel Qureshi - Frontend Developer</Card.Header>
              <Card.Body>
                <Card.Text>
                Desc goes here
                </Card.Text>
              </Card.Body>
          </Card>
      </CardGroup>
      </div>
      </div>
    );
  };
    
  export default MeetTheTeam;