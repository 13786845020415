import '../Styles/vendor.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faRectangleList, faBookOpenReader, faCommentDollar, faIdCard, faClock, faMoneyCheckDollar, faHeadset, faGear } from "@fortawesome/free-solid-svg-icons"
import { useState } from 'react';

const Vendor = () => {
  const [activeTab, setActiveTab] = useState('home');
  function handleTabClick(tabName) {
    setActiveTab(tabName);
  }
  return (
    <div className="vendor-portal">
      <Row>
        <Col className='nav-links'>
          <ul>
            <li><button className={activeTab === 'home' ? 'active' : ''} onClick={() => handleTabClick("home")}><FontAwesomeIcon icon={faHouse} />Home</button></li>
            <li><button className={activeTab === 'ordersummary' ? 'active' : ''} onClick={() => handleTabClick("ordersummary")}><FontAwesomeIcon icon={faRectangleList} />Order Summary</button></li>
            <li><button className={activeTab === 'menu' ? 'active' : ''} onClick={() => handleTabClick("menu")}><FontAwesomeIcon icon={faBookOpenReader} />Menu</button></li>
            <li><button className={activeTab === 'marketing' ? 'active' : ''} onClick={() => handleTabClick("marketing")}><FontAwesomeIcon icon={faCommentDollar} />Marketing</button></li>
            <li><button className={activeTab === 'profile' ? 'active' : ''} onClick={() => handleTabClick("profile")}><FontAwesomeIcon icon={faIdCard} />Profile</button></li>
            <li><button className={activeTab === 'hoo' ? 'active' : ''} onClick={() => handleTabClick("hoo")}><FontAwesomeIcon icon={faClock} />Hours of Operations</button></li>
            <li><button className={activeTab === 'payment' ? 'active' : ''} onClick={() => handleTabClick("payment")}><FontAwesomeIcon icon={faMoneyCheckDollar} />Payment</button></li>
            <li><button className={activeTab === 'support' ? 'active' : ''} onClick={() => handleTabClick("support")}><FontAwesomeIcon icon={faHeadset} />Support</button></li>
          </ul>
        </Col>
        <Col>
          <div className='vendor-view'>
            {activeTab === 'home' && (
              <div className='home-tab'>
                <div className="banner">
                </div>
                <div className="info">
                  <p>
                    Bonton, Indian Catering | Milton, ON (14.3KM)
                  </p>
                  <p>
                    Minimum 4 hours notice | Order size: 20-500
                  </p>
                  <Button> <FontAwesomeIcon icon={faGear} /> Edit Profile</Button>
                </div>
                <div className='info-cards'>
                  <Row>
                    <Col>
                      <span>Top Selling Items</span>
                    </Col>
                    <Col>
                      <span>Menu Item Feedback</span>
                    </Col>
                    <Col>
                      <span>Operations</span>

                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {activeTab === 'ordersummary' && (
              <div className='order-summary-tab'>
                Order Summary Tab
              </div>
            )}
            {activeTab === 'menu' && (
              <div className='menu-tab'>
                Menu Tab
              </div>
            )}
            {activeTab === 'marketing' && (
              <div className='marketing-tab'>
                Marketing Tab
              </div>
            )}
            {activeTab === 'profile' && (
              <div className='profile-tab'>
                Profile Tab
              </div>
            )}
            {activeTab === 'hoo' && (
              <div className='hoo-tab'>
                Hours of Operations Tab
              </div>
            )}
            {activeTab === 'payment' && (
              <div className='payment-tab'>
                Payment Tab
              </div>
            )}
            {activeTab === 'support' && (
              <div className='support-tab'>
                Support Tab
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Vendor;